

import React, { useState } from "react";
import team from "./hiring-concept-with-people-coming-together_23-2149519873.avif";
import axios from "axios";
import "./Careers.css";
import { Helmet } from "react-helmet-async";

function Careers() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    resume: null,
  });
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataObject = new FormData();
    formDataObject.append("name", formData.name);
    formDataObject.append("email", formData.email);
    formDataObject.append("resume", formData.resume);
  
    try {
      const response = await axios.post("http://your-server-url/upload.php", formDataObject, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      if (response.data.status === "success") {
        alert("Resume uploaded successfully!");
      } else {
        alert("Upload failed: " + response.data.message);
      }
    } catch (error) {
      alert("Error uploading resume.");
    }
  };
  
  return (
    <>
    <Helmet>
            <title>
            No.1 Training Institute in Coimbatore| 100 % Placement 
            </title>
            <meta
              name="description"
              content=" Looking for a rewarding career? Your dream job starts here. Join our team of 
experts and be a part of something great. Apply now."
            />
            <meta
              name="keywords"
              content="Top web development training with certification, Software Training Courses in 
Coimbatore"
            />
          </Helmet>
      <div className="careers-container">
        {/* Hero Section */}
        <img src={team} alt="Join Our Team" className="hero-image" />
        <div></div>

        <div className="join-team">
          <h1 className="contents">Join Our Team</h1>
          <p className="p-content">
            If you are looking to work on challenging assignments in leading
            fortune 500 companies and looking to advance your professional
            career, you are at the right place.
          </p>
        </div>

        {/* Why Work With Us? */}
        <section className="careers-info">
          <h2 className="contents">Why Work With Us?</h2>
          <div className="benefits-grid">
            <div className="benefit-card">
              <span>🌟</span>
              <h3>Impactful Projects</h3>
              <p className="p-content">
                Work on real-world projects that make a difference.
              </p>
            </div>
            <div className="benefit-card">
              <span>🤝</span>
              <h3>Collaborative Culture</h3>
              <p className="p-content">
                Be part of a supportive and innovative team.
              </p>
            </div>
            <div className="benefit-card">
              <span>🚀</span>
              <h3>Career Growth</h3>
              <p className="p-content">
                Unlock new opportunities and grow with us.
              </p>
            </div>
          </div>
        </section>

        {/* Job Listings */}
        <section className="job-listings">
          <h2 className="contents">Find The Right Job</h2>
          <div className="jobs-grid">
            <div className="job-card">
              <h3 className="contents">React+Node Fullstack Developer</h3>
              <p className="p-content">4 years experience</p>
              <button className="learn-more-button">Learn More</button>
            </div>
            <div className="job-card">
              <h3 className="contents">Business Developer</h3>
              <p className="p-content">0-1 year experience</p>
              <button className="learn-more-button">Learn More</button>
            </div>
            <div className="job-card">
              <h3 className="contents">SEO Analyst</h3>
              <p className="p-content">2-4 years experience</p>
              <button className="learn-more-button">Learn More</button>
            </div>
            <div className="job-card">
              <h3 className="contents">Admin</h3>
              <p className="p-content">Fresher</p>
              <button className="learn-more-button">Learn More</button>
            </div>
            <div className="job-card">
              <h3 className="contents">Telecaller</h3>
              <p className="p-content">Minimum 2 years experience</p>
              <button className="learn-more-button">Learn More</button>
            </div>
          </div>
        </section>

        {/* Internship Section */}
        <section className="internship-section">
          <h2 className="contents">INTERNSHIP</h2>
          <p className="p-content">
            For the past decade Kitkat software technologies providing
            internship with certification. Aspiring to kick start your career in
            Software industry For that we offer fantastic opportunity to join
            with kitkat software technologies and make hands on experience and
            recognized certification.
          </p>
          <p className="p-content">
            Our internship programmes are tailored to College students who are
            ready to build strong foundation to their career. As an leading
            Company we provide the hands on experience with affordable cost
          </p>
          <p className="p-content">
            Contact us to know more about the Internship training program at
            kitkat software technologies.
          </p>
        </section>

        {/* Resume Upload Form */}
        <section className="resume-upload">
        <h2 className="contents">Upload Your Resume</h2>
        <form className="resume-form" >
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <input type="file" name="resume" required />
          <button type="submit">Upload & Send</button>
        </form>
      </section>
      </div>
    </>
  );
}

export default Careers;






// php backend code 

// <?php
// header("Access-Control-Allow-Origin: *");
// header("Access-Control-Allow-Methods: POST");
// header("Access-Control-Allow-Headers: Content-Type");

// if ($_SERVER["REQUEST_METHOD"] === "POST") {
//     if (isset($_FILES["resume"]) && isset($_POST["name"]) && isset($_POST["email"])) {
//         $uploadDir = "uploads/"; // Folder to store resumes
//         if (!is_dir($uploadDir)) {
//             mkdir($uploadDir, 0777, true); // Create folder if it doesn't exist
//         }

//         $fileName = basename($_FILES["resume"]["name"]);
//         $targetFilePath = $uploadDir . $fileName;
        
//         // Move uploaded file to target directory
//         if (move_uploaded_file($_FILES["resume"]["tmp_name"], $targetFilePath)) {
//             echo json_encode(["status" => "success", "message" => "Resume uploaded successfully"]);
//         } else {
//             echo json_encode(["status" => "error", "message" => "File upload failed"]);
//         }
//     } else {
//         echo json_encode(["status" => "error", "message" => "Missing required fields"]);
//     }
// } else {
//     echo json_encode(["status" => "error", "message" => "Invalid request method"]);
// }
// ?>

