import React from "react";
import "./Contacts.css";
import { Helmet } from "react-helmet-async";

const ContactPage = () => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) => currentYear - i);

  return (
    <>
      <Helmet>
        <title>Kitkat software Technologies | Get in touch with us now</title>
        <meta
          name="description"
          content="  Let’s connect today! For your enquiries, support, and career opportunities. Our 
team is here to help, and we’re happy to assist you."
        />
      </Helmet>
      <div className="fullcontrol">
        {/* Google Map */}
        <div className="contact-page-map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.322585449735!2d76.9765124641843!3d11.01440580776919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859b2d555eeff%3A0xbcb671713da40ffb!2sSri+Krishna+Complex%2C+72%2C+Kattoor+Rd%2C+P+N+Palayam%2C+Coimbatore%2C+Tamil+Nadu+641037!5e0!3m2!1sen!2sin!4v1510310937877"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: "0" }}
            allowFullScreen
            title="map"
          ></iframe>
        </div>

        {/* Contact Info */}
        <div className="contact-page-container">
          <div className="contact-page-info">
            <h2 className="contact-page-title">Kitkat Software Technologies</h2>
            <ul className="contact-page-list">
              <li>
                <i className="fa-solid fa-location-dot"></i>
                No.70/77, 1st floor, Krishna Complex, Kattor Road,
                Papanaickenpalayam, Coimbatore-641037
              </li>
              <li>
                <i id="phone2" className="fa-solid fa-phone"></i> Phone: 0422
                495 7272
              </li>
              <li>
                <i id="phone1" className="fa-solid fa-mobile"></i> Mobile:
                +917010816299
              </li>
              {/* <li>
              For HR inquiries:{" "}
              <a
                href="mailto:kitkatsoftwaretechnologies@gmail.com"
                className="contact-page-email-link"
              >
                kitkatsoftwaretechnologies@gmail.com
              </a>
            </li> */}
            </ul>
          </div>
        </div>

        {/* Registration Form */}
        <div className="contact-page-form-container">
          <div className="contact-page-form">
            <h2 className="contact-page-form-title">
              Register for More Information
            </h2>

            <form
              action="mailto:kitkatsoftwaretechnologies@gmail.com"
              method="post"
              encType="text/plain"
            >
              <div className="contact-page-form-group">
                <label htmlFor="first-name" className="contact-page-label">
                  Name
                </label>
                <input
                  type="text"
                  name="first-name"
                  placeholder="Enter your first name"
                  maxLength="20"
                  required
                  className="contact-page-input"
                />
              </div>
{/* 
              <div className="contact-page-form-group">
                <label htmlFor="last-name" className="contact-page-label">
                  Last Name
                </label>
                <input
                  type="text"
                  id="last-name"
                  name="last-name"
                  placeholder="Enter your last name"
                  maxLength="20"
                  required
                  className="contact-page-input"
                />
              </div> */}

              <div className="contact-page-form-group">
                <label htmlFor="email" className="contact-page-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  required
                  className="contact-page-input"
                />
              </div>

              <div className="contact-page-form-group">
                <label htmlFor="phone" className="contact-page-label">
                  Mobile Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Enter your mobile number"
                  maxLength="10"
                  required
                  className="contact-page-input"
                />
              </div>

              <div className="contact-page-form-group">
                <label htmlFor="degree" className="contact-page-label">
                  Purpose of Contact
                </label>
                <input
                  type="text"
                  id="degree"
                  name="degree"
                  placeholder="Enter your degree"
                  required
                  className="contact-page-input"
                />
              </div>

              {/* <div className="contact-page-form-group">
                <label htmlFor="passout-year" className="contact-page-label">
                  Pass Out Year
                </label>
                <select
                  id="passout-year"
                  name="passout-year"
                  required
                  className="contact-page-select"
                >
                  <option value="">Select Year</option>
                  {years.map((year) => (
                    <option
                      style={{ backgroundColor: "rgb(98, 215, 254)" }}
                      key={year}
                      value={year}
                    >
                      {year}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="contact-page-form-group">
                <button type="submit" className="contact-page-submit-button">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
